.sb-soundplayer-progress-container {
  background-color: #DDD;
  width: 100%;
  height: 10px;
  overflow: hidden;
  cursor: pointer;
}

.sb-soundplayer-progress-inner {
  background-color: #FC561E;
  height: 100%;
  -webkit-transition: width .2s ease-in;
  transition: width .2s ease-in;
}
